/*
 * Fichier CSS principal avec Tailwind CSS 
 * Les directives comme @tailwind et @layer sont traitées par le préprocesseur
 */

/* Tailwind Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Amélioration de l'expérience tactile sur mobile */
@media (max-width: 768px) {
  /* Augmenter la taille des zones tactiles */
  button, a, [role="button"] {
    min-height: 44px;
    min-width: 44px;
  }
  
  /* Améliorer l'espacement vertical */
  .form-group, .input-group {
    margin-bottom: 1.25rem;
  }
  
  /* Assurer que le contenu ne déborde pas horizontalement */
  html, body {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Améliorer la lisibilité du texte */
  p, li, div {
    line-height: 1.5;
  }
  
  /* Taille de police minimale pour la lisibilité */
  html {
    font-size: 16px;
  }
  
  /* Améliorer les formulaires sur mobile */
  input, select, textarea {
    font-size: 16px; /* Évite le zoom automatique sur iOS */
    padding: 0.75rem;
  }
  
  /* Ajuster la hauteur des champs de texte */
  textarea {
    min-height: 100px;
  }
}

/* Amélioration de la mise en page sur très petits écrans */
@media (max-width: 380px) {
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }
  
  h1 {
    font-size: 1.5rem;
  }
  
  h2 {
    font-size: 1.25rem;
  }
}

/* Classes utilitaires personnalisées qui seront générées par Tailwind */
/* Les directives @layer et @apply sont commentées pour éviter les erreurs */

/* 
@layer base {
  body {
    @apply bg-gray-50 text-gray-900;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-medium;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded-md font-medium transition-colors;
  }
  
  .btn-primary {
    @apply bg-blue-600 text-white hover:bg-blue-700;
  }
  
  .btn-secondary {
    @apply bg-gray-200 text-gray-700 hover:bg-gray-300;
  }
  
  .btn-success {
    @apply bg-green-600 text-white hover:bg-green-700;
  }
  
  .input {
    @apply w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500;
  }
  
  .card {
    @apply bg-white rounded-lg shadow p-6;
  }
}
*/ 

/* Animations pour Saul et les popups */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(10px) scale(0.98);
  }
}

/* Animation pour le curseur clignotant dans les popups */
@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.animate-blink {
  animation: blink 0.8s infinite;
}

.animate-fadeIn {
  animation: fadeIn 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.animate-fadeOut {
  animation: fadeOut 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) forwards;
}

/* Animation pour des transitions plus fluides */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Animation pour le gradient */
@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient-slow {
  animation: gradient-shift 8s ease infinite;
}

.bg-gradient-size {
  background-size: 200% 200%;
} 

/* Effet de surbrillance pour les éléments mis en évidence */
@keyframes pulse-highlight {
  0%, 100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.5);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(59, 130, 246, 0);
  }
}

.animate-highlight {
  animation: pulse-highlight 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse-slow {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
}

.animate-pulse-slow {
  animation: pulse-slow 3s ease-in-out infinite;
} 